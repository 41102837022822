import i18n from '@/languages/i18n';

export enum CounterType {
  TotalCounter = 0,
  WeeklyCounter = 1,
  TriggerCounter = 2
}

export const CounterTypeDropdown = () => [
  { id: CounterType.TotalCounter, title: i18n.t('dropdowns.counterTypes.totalCounter').toString() },
  {
    id: CounterType.WeeklyCounter,
    title: i18n.t('dropdowns.counterTypes.weeklyCounter').toString()
  },
  {
    id: CounterType.TriggerCounter,
    title: i18n.t('dropdowns.counterTypes.triggerCounter').toString()
  }
];

export default CounterType;
