



















































































import Vue from 'vue';
import axios from 'axios';
import {
  SmartTable,
  Config,
  Column,
  Sorting,
  PagingOptions,
  toDateTimeString
} from 'rey-vue-smarttable';
import CounterEndpoint from '@/models/CounterEndpoint';
import { CounterType, CounterTypeDropdown } from '@/models/CounterType';
import OpcUaValidationResult from '@/models/OpcUaValidationResult';

export default Vue.extend({
  name: 'counters',
  components: { SmartTable },
  data() {
    return {
      smartTableConfig: new Config(
        (row) => row.counterEndpointId,
        [
          new Column({
            title: this.$t('views.systemadmin.counters.tableHeaders.id').toString(),
            fieldName: 'counterEndpointId',
            fieldType: 'numeric',
            editable: () => false,
            hidden: true,
            hiddenInAddDialog: true
          }),
          new Column({
            title: this.$t('views.systemadmin.counters.tableHeaders.displayName').toString(),
            fieldName: 'displayName',
            fieldType: 'string',
            editable: () => true,
            validator: (newValue) =>
              // @ts-ignore
              this.validateString(newValue, 50)
          }),
          new Column({
            title: this.$t('views.systemadmin.counters.tableHeaders.description').toString(),
            fieldName: 'description',
            fieldType: 'string',
            editable: () => true,
            validator: (newValue) =>
              // @ts-ignore
              this.validateString(newValue, 255)
          }),
          new Column({
            title: this.$t('views.systemadmin.counters.tableHeaders.unit').toString(),
            fieldName: 'unit',
            fieldType: 'string',
            editable: () => true,
            validator: (newValue) =>
              // @ts-ignore
              this.validateString(newValue, 10)
          }),
          new Column({
            title: this.$t('views.systemadmin.counters.tableHeaders.type').toString(),
            fieldName: 'counterType',
            fieldType: 'dropdown',
            editable: () => true,
            dropdownOptions: CounterTypeDropdown(),
            defaultValue: CounterType.TotalCounter,
            description: this.$t('views.systemadmin.counters.descriptions.type').toString()
          }),
          new Column({
            title: this.$t('views.systemadmin.counters.tableHeaders.validatedAt').toString(),
            fieldName: 'validatedAt',
            fieldType: 'string',
            editable: () => false,
            cellStyling: () => ({
              'text-muted': true
            }),
            converter: toDateTimeString,
            hiddenInAddDialog: true
          }),
          new Column({
            title: this.$t('views.systemadmin.counters.tableHeaders.validationResult').toString(),
            fieldName: 'validationResult',
            fieldType: 'string',
            editable: () => false,
            hiddenInAddDialog: true,
            cellStyling: (x) => {
              return { hiddenCellValue: x.isDisabled };
            }
          }),
          new Column({
            title: this.$t('views.systemadmin.counters.tableHeaders.valueOpcUaPath').toString(),
            fieldName: 'valueOpcUaPath',
            fieldType: 'string',
            editable: () => true,
            validator: (newValue: string) =>
              // @ts-ignore
              this.validateString(newValue, 255),
            description: this.$t(
              'views.systemadmin.counters.descriptions.valueOpcUaPath'
            ).toString()
          }),
          new Column({
            title: this.$t('views.systemadmin.counters.tableHeaders.dateOpcUaPath').toString(),
            fieldName: 'dateOpcUaPath',
            fieldType: 'string',
            editable: () => true,
            validator: (newValue: string, row: CounterEndpoint) =>
              // @ts-ignore
              this.validateOpcUaPath(newValue, row.counterType !== CounterType.TotalCounter),
            description: this.$t('views.systemadmin.counters.descriptions.dateOpcUaPath').toString()
          }),
          new Column({
            title: this.$t('views.systemadmin.counters.tableHeaders.triggerOpcUaPath').toString(),
            fieldName: 'triggerOpcUaPath',
            fieldType: 'string',
            editable: () => true,
            validator: (newValue: string, row: CounterEndpoint) =>
              // @ts-ignore
              this.validateOpcUaPath(newValue, row.counterType === CounterType.TriggerCounter),
            description: this.$t(
              'views.systemadmin.counters.descriptions.triggerOpcUaPath'
            ).toString()
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.counters.tableHeaders.acknowledgeOpcUaPath'
            ).toString(),
            fieldName: 'acknowledgeOpcUaPath',
            fieldType: 'string',
            editable: () => true,
            validator: (newValue: string, row: CounterEndpoint) =>
              // @ts-ignore
              this.validateOpcUaPath(newValue, row.counterType === CounterType.TriggerCounter),
            description: this.$t(
              'views.systemadmin.counters.descriptions.acknowledgeOpcUaPath'
            ).toString()
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.counters.tableHeaders.counterNumberOpcUaPath'
            ).toString(),
            fieldName: 'counterNumberOpcUaPath',
            fieldType: 'string',
            editable: () => true,
            validator: (newValue: string, row: CounterEndpoint) =>
              // @ts-ignore
              this.validateOpcUaPath(newValue, row.counterType === CounterType.TriggerCounter),
            description: this.$t(
              'views.systemadmin.counters.descriptions.counterNumberOpcUaPath'
            ).toString()
          }),
          new Column({
            title: this.$t('views.systemadmin.counters.tableHeaders.counterNumber').toString(),
            fieldName: 'counterNumber',
            fieldType: 'numeric',
            editable: () => true,
            validator: (newValue: number, row: CounterEndpoint) =>
              row.counterType !== CounterType.TriggerCounter
                ? // @ts-ignore
                  this.validateNumberRange(newValue, 0, 0)
                : // @ts-ignore
                  this.validateNumberRange(newValue, 0, 100),
            description: this.$t('views.systemadmin.counters.descriptions.counterNumber').toString()
          })
        ],
        new Sorting('displayName', 'ascending'),
        new PagingOptions(100)
      ).withEfApiActions(axios, 'CounterEndpoint'),
      selected: undefined as CounterEndpoint | undefined,
      validationInProgress: false as boolean
    };
  },
  methods: {
    validateOpcUaPath(value: string, isRequired: boolean) {
      return isRequired ? this.validateString(value, 255) : this.validateStringEmpty(value);
    },
    validateNumberRange(value: number, minValue: number, maxValue: number) {
      return {
        isValid: !!value && value >= minValue && value <= maxValue,
        errorMessages: [
          this.$t('validationErrors.invalidNumberRange', {
            currentValue: value,
            minValue: minValue,
            maxValue: maxValue
          }).toString()
        ]
      };
    },
    validateString(value: string, maxLength: number) {
      return {
        isValid: !!value && value.length <= maxLength,
        errorMessages: [
          this.$t('validationErrors.invalidTextLength', {
            currentValue: value,
            maxLength: maxLength
          }).toString()
        ]
      };
    },
    validateStringEmpty(value: string) {
      return {
        isValid: value === undefined || value === '',
        errorMessages: [
          this.$t('validationErrors.valueMustNotBeSet', {
            currentValue: value
          }).toString()
        ]
      };
    },
    async validateCounters() {
      this.validationInProgress = true;
      await this.$httpActions
        .executePost('ValidateCounterEndpoints', '{}')
        .catch(() => (this.validationInProgress = false))
        .finally(() => {
          this.validationInProgress = false;
          // @ts-ignore
          this.$refs.smartTable.reload();
        });
    },
    getValidationResults(validationResultString: string): OpcUaValidationResult[] {
      return JSON.parse(validationResultString);
    }
  }
});
